.rca-form-container {
    background-color: #fefefe;
    padding: 2rem;
    border-radius: 5px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    max-width: 700px;
    margin: auto;
    margin-top: 40px;
    margin-bottom: 40px;
  }
  
  .buton-rca {
    font-size: 20px;
  }
  

  .rca-form-container h2 {
    color: #343a40;
    font-weight: bold;
  }
  
  .rca-form-container .form-control,
  .rca-form-container .form-check-input {
    border-radius: 5px;
  }
  
  .rca-form-container .btn-primary {
    background-color: #007bff;
    border: none;
  }
  
  .rca-form-container .btn-primary:hover {
    background-color: #0056b3;
  }
  
  .custom-file-input {
  display: flex;
  align-items: center;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  }

  .file-name {
    font-size: 0.9rem;
    color: #6c757d;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 250px;
  }

  .custom-file-button {
    border-radius: 5px 0 0 5px;
    background-color: #C9C9C9;
    color: black;
    border: 0px;
  }

