@import url('https://fonts.googleapis.com/css2?family=Afacad+Flux:wght@100..1000&display=swap');

body {
    background-color: #f4faff !important;
}

p {
    font-size: 18px;
}

li {
    font-size: 18px;
}