.contact-page {
    background-color: #fefefe;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    padding: 3rem;
    margin-top: 30px;
    margin-bottom: 40px;
  }

  .heading-contact {
    margin-bottom: 40px !important;
  }
  
  .contact-page h2 {
    font-size: 2.5rem;
    font-weight: bold;
    color: #343a40;
  }
  
  .contact-page .text-muted {
    font-size: 1.1rem;
    color: #6c757d;
  }
  
  .contact-image {
    max-width: 80%;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  }
  
  .contact-details h4 {
    font-size: 1.75rem;
    color: #111111;
    margin-bottom: 1rem;
  }
  
  .contact-details p {
    font-size: 1.2rem;
    margin-bottom: 0.5rem;
    color: #343a40;
  }

  .business-info {
    border-bottom: 1px !important;
    border-color: 1px solid black !important;
  }
  
  .divider-contact {
    height: 2px;
    color: black;
  }

  @media (max-width: 768px) {
    .contact-image {
      max-width: 100% !important;
    }
  }