.navbar {
    transition: 0.4s ease-in-out;
    background-color: #ffffff;
    font-family: "Afacad Flux", sans-serif !important;
    font-size: 18px;
  }
  
  .navbar.fixed {
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 1000; /* Adjust z-index if necessary */
    transition: 0.6s ease-in-out;
    background-color: #fefefe;
    border-bottom: 1px solid rgb(178, 178, 178);
  }
  

@media (max-width: 991px) {
    .sidebar {
        background-color: #fefefe;
    }
    .nav-responsive-btn {
        margin-top: 20px;
    }
    .login-btn {
        width: 100%;
    }
    .rca-btn {
        width: 100%;    
    }
    .btn-register {
        width: 100%;
    }
}


.logo {
    font-family: "Afacad Flux", sans-serif !important;
    font-weight: 600 !important;
    font-size: 30px !important;
    color:#111111 !important;
}

.login-btn {
    color: #111111 !important;
    text-decoration: none !important;
    font-family: "Afacad Flux", sans-serif !important;
    font-size: 18px !important;
}

.login-btn:hover {
    color: #090909 !important;
}

.rca-btn {
    font-family: "Afacad Flux", sans-serif !important;
    font-weight: 400 !important;
    font-size: 18px !important;
    background-color: transparent !important;
    color: #111111 !important;
    border: 1px solid #111111 !important;
    border-radius: 5px !important;
}

.login-icon {
    padding-right: 5px;
}

.nav-link {
    color: #090909 !important;
}

.color-span {
    color: #007bff;
}

.other-logos {
    max-width: 80px;
    height: auto;
}

/* Authenticated user*/

.logout-icon {
    padding-right: 5px;
    font-size: 16px;
}

.logout-btn {
    font-size: 18px !important;
    color: #5a5a5a !important;
    text-decoration: none !important;
} 

.notifications-icon {
    padding-right: 5px;
    font-size: 16px;
}

.notifications-btn {
    font-size: 18px !important;
    color: #111111 !important;
    text-decoration: none !important;
}

.btn-register {
    font-size: 18px !important;
    margin-right: 10px;
    vertical-align: middle !important;
}