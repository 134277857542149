.form-signup {
    width: 400px;
    background-color: #FEFEFE;
    padding: 20px;
    box-shadow:
    1.3px 0.2px 1.5px rgba(0, 0, 0, 0.009),
    3.3px 0.4px 3.7px rgba(0, 0, 0, 0.013),
    6.7px 0.9px 7.6px rgba(0, 0, 0, 0.017),
    13.9px 1.8px 15.7px rgba(0, 0, 0, 0.021),
    38px 5px 43px rgba(0, 0, 0, 0.03)
    ;
    border-radius: 5px;
}


.form-signup-container {
   justify-content: center;
   max-width: fit-content;
   margin-left: auto;
   margin-right: auto;
   padding-top: 60px;
   padding-bottom: 60px;
}

.btn-size-signup{ 
  font-size: 20px;
}

.placeholder-style {
    color: #8787877b !important;
}

/* Responsive size for mobile devices and tablets */
@media (max-width: 425px){
    .form-signup {
        width: 100%;
    }
    .form-signup-container {
        justify-content: start;
        vertical-align: middle !important;
    }
}