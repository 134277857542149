.card {
    border: 0;
    border-radius: 10px;
    background-color: #FEFEFE;
    box-shadow: 0px 10px 15px -3px rgba(0,0,0,0.1);
}

.card-header {
    background-color: #393939;
    color: #FEFEFE;
    border: 0;  
}   

.form-text {
    font-size: 18px;    
    color: #111111;
}

.card-btn {
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 10px;
}

.card-pw {
    background-color: rgb(147, 14, 14);
}