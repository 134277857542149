.image-row {
    display: inline;
    align-items: center;
    justify-content: flex-start; 
  }
  
  .image-row img {
    margin-right: 10px; 
  }

  .copyright {
    background-color: #FEFEFE;
    padding: 10px !important;
    font-size: 18px;
    border-top: 1px solid #d9d9d9;
    color: rgb(80, 80, 80);
  }

  .footer-container {
    position: relative;
    bottom: 0;
    font-size: 18px;
  }

  @media (max-width: 768px) {
    .footer-responsive {
      padding-left: 20px;
    }
  }

  /* Asiguratori - Grafice */
  .logo-container {
    display: grid;
    grid-template-columns: repeat(9, 1fr); /* 3 columns */
    gap: 20px; /* space between logos */
    vertical-align: middle;
    background-color: white;
    border-radius: 20px;
    box-shadow: 0px 0px 17px -3px rgba(0,0,0,0.1);
    margin-bottom: 40px;
  }
  
  .logo-container img {
    width: 100%; 
    max-width: 100px; 
    max-height: 40px;
    margin: auto;
  }

  .link-web {
    color: black;
    text-decoration: none;
  }

  @media (max-width: 768px) {
    .logo-container {
        grid-template-columns: repeat(3, 1fr);
    }
  }