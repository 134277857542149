/* General container styling */
.userpanel-container {
  padding-top: 80px;
  max-width: 1310px;
  margin: 0 auto;
  font-family: 'Arial', sans-serif;
}

.userpanel-heading {
  border-bottom: 1px solid #D9D9D9;
  padding-bottom: 10px;
}

.userpanel-heading-text {
  text-align: left;
  font-size: 24px;
  font-weight: bold;
}

.userpanel-heading-paragraph {
  font-size: 16px;
  color: rgb(80, 80, 80);
}

.userpanel-big-title {
  margin-left: 20px !important;
}

/* Cars button section */
.button-car {
  background-color: #aedcff;
  border: 1px solid #11111100;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 16px;
  color: #333;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.button-car.active-car {
  background-color: #0C7ED5;
  color: white;
  border-color: #0C7ED5;
}

.button-car:hover {
  background-color: rgb(5, 95, 192);
  color: white;
}

/* Document rows styling */
.row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.userpanel-row {
  border: 1px solid #cccccc00;
  border-radius: 20px;
  padding: 10px 15px;
  background-color: #fff;
  box-shadow: 0px 0px 17px -3px rgba(0,0,0,0.1);
}

/* Labels and inputs */
label {
  color: #111;
  font-size: 16px;
}

.input-table {
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 5px 10px;
  font-size: 14px;
  width: 80%;
}
.input-table:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.car-name-input {
  border: 1px solid #7b7b7b;
  padding: 10px;
  border-radius: 5px; 
  background-color: rgb(255, 255, 255);
  color: #111;
  width: min-content;
  max-width: 120px;
  text-align: center;
}

/* Days left styling */
.days-left {
  padding: 5px 10px;
  border-radius: 5px;
  text-align: center;
  font-weight: bold;
  font-size: 14px;
  max-width: max-content;
  padding: 10px 15px 10px 15px;
  color: white !important;
  margin: auto 0;
}

/* Toggle switches */
.switch-col {
  display: flex;
  align-items: center;
}

/* Switch Button Container */
.switch-col {
  display: flex;
  align-items: center;
  gap: 10px; /* Space between label/text and the switch */
}

/* Switch Button Styling */
.switch-button {
  position: relative;
  display: inline-block;
  width: 50px; /* Slightly larger for better visibility */
  height: 30px;
  vertical-align: middle;
}

/* Hide Default Checkbox */
.switch-button input {
  opacity: 0;
  width: 0;
  height: 0;
}

.switch-text {
  margin: auto 0;
  font-weight: 600;
}

/* Slider Styling */
.switch-button .slider {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  border-radius: 22px; /* Matches the height for a round switch */
  transition: background-color 0.4s ease;
}

/* Slider Circle */
.switch-button .slider:before {
  position: absolute;
  content: "";
  height: 24px; /* Slightly smaller than the slider height */
  width: 24px;
  left: 4px; /* Space from the edge */
  bottom: 3.5px;
  background-color: white;
  border-radius: 50%;
  transition: transform 0.4s ease;
}

/* When Checked */
.switch-button input:checked + .slider {
  background-color: #007bff; /* Blue when active */
}

.switch-button input:checked + .slider:before {
  transform: translateX(18px); /* Move the circle to the right */
}

/* Hover State */
.switch-button .slider:hover {
  background-color: #b3d7ff; /* Light blue on hover */
}

/* Buttons */
.button-save, .button-edit, .button-abandon, .button-delete {
  padding: 10px 20px;
  font-size: 14px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s, color 0.3s;
}

.button-save {
  background-color: #28a745;
  color: white;
}

.button-save:hover {
  background-color: #218838;
}

.button-edit {
  background-color: #007bff;
  color: white;
}

.button-edit:hover {
  background-color: #0056b3;
}

.button-abandon {
  background-color: #ffc107;
  margin-left: 20px;
  color: black;
}

.button-abandon:hover {
  background-color: #e0a800;
}

.button-delete {
  background-color: #dc3545;
  color: white;
}

.button-delete:hover {
  background-color: #c82333;
}

/* Responsive styling */
@media (max-width: 768px) {
  .row {
      flex-direction: column;
      align-items: flex-start;
  }

  .col {
      width: 100%;
      margin-bottom: 10px;
  }

  .switch-col {
      justify-content: space-between;
  }

  .button-car {
      margin-bottom: 10px;
  }

  .userpanel-container {
      padding: 10px;
  }

  .label-name {
    font-size: 21px;
    margin-top: 10px;
  }

  .input-table {
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 16px;
  }
  .days-left {
    font-size: 21px;
    margin-bottom: 10px;
  }
  .data-index {
    display: none;
  }
  .userpanel-big-title{
    padding-top: 50px;
  }
}


/* Editing Buttons Style */
.edit-table-div {
  position: fixed;
  bottom: 0;
  background-color: #ffffff;
  border-top: 1px solid #ccc;
  margin: 0;
  min-width: 100%;
  text-align: center;
  padding-bottom: 10px;
  padding-top: 10px;
  z-index: 99;
}

.edit-table-div button {
  font-size: 16px;
  font-weight: 400;
}

/* Notepad CSS */



.notepad-textarea {
  width: 100% !important;
  height: 200px !important;
}