/* General */
body {
    font-family: "Afacad Flux", serif !important;
}

.img-rounded {
    border-radius: 10px;
    box-shadow: 0px 0px 17px -3px rgba(0,0,0,0.1);
}

.btn-primary {
    background-color: #0C7ED5;
    border: 0;
}

.btn-primary:hover {
    background-color: #12619d;
}

.btn-outline-secondary {
    background-color: transparent;
    border: 1px solid #111111; 
    color: #111111;
}

.btn-outline-secondary:hover {
    background-color: #0e426a3f;
    border: 1px solid #111111; 
    color: #111111;
}

.icon-check {
    font-size: 24px;
    color: #0C7ED5;
    text-align: start;
    justify-content: start;
}

.lead {
    color: #111111 !important;
}

/* Hero Section */
.hs-title {
    line-height: 70px;
    font-weight: 500 !important;
    font-family: "Afacad Flux", serif !important;
}

@media (min-width: 1199px)
{
    .hs-title {
        padding-left: 400px;
        padding-right: 400px;
    }
}


/* Responsive - Mobile Devices */

@media (max-width: 467px)
{
    .hero-section {
        margin-top: 40px !important;
    }

    .hs-title {
        line-height: 40px !important;
        text-align: left !important;
    }

    .text-phone {
        text-align: left !important;
    }

    .section-2 {
        margin-top: 0px !important;
        margin-bottom: 0px !important;
    }

    .section-3 {
        margin-top: 0px !important;
        padding-top: 0px !important;
    }
    
    .gap-phone {
        gap: 0 !important;
    }


}


/* Asiguratori - Grafice */

.logo-container {
    display: grid;
    grid-template-columns: repeat(9, 1fr); /* 3 columns */
    gap: 20px; /* space between logos */
    vertical-align: middle;
    background-color: white;
    border-radius: 20px;
    box-shadow: 0px 0px 17px -3px rgba(0,0,0,0.1);
    margin-bottom: 40px;
  }
  
  .logo-container img {
    width: 100%; 
    max-width: 100px; 
    max-height: 40px;
    margin: auto;
  }

  @media (max-width: 768px) {
    .logo-container {
        grid-template-columns: repeat(3, 1fr);
    }
  }
  